<template>
  <div class="alpha">
    <div class="section-alpha-1">
      <p class="section-header-1">Choose an Investment Plan</p>
      <p class="section-header">You don't have an account yet? start trading with TopLeads Trade.</p>
    </div>

    <div class="section-alpha">

      <div class="third-section top">
        <p class="text-1">Silver</p>
        <p class="text-2">10<span class="text-2-inner">%</span></p>
        <!--        <p class="text-2">4 Months</p>-->
        <p class="text-3">4 Months</p>

        <div class="btn" @click="onPostClick" >
          <p>Invest Now</p>
        </div>

        <div class="separate">
          <p>$5,000 MINIMUM DEPOSIT</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>ENHANCED SECURITY</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>1.2% DAILY</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>GUARANTEED ROI</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>24/7 SUPPORT</p>
          <i class='bx bxs-info-circle'></i>
        </div>
      </div>

      <div class="third-section top">
        <p class="text-1">Gold</p>
        <p class="text-2">20<span class="text-2-inner">%</span></p>
        <!--        <p class="text-2">4 Months</p>-->
        <p class="text-3">8 Months</p>

        <div class="btn" @click="onPostClick" >
          <p>Invest Now</p>
        </div>

        <div class="separate">
          <p>$25,000 MINIMUM DEPOSIT</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>ENHANCED SECURITY</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>1.4% DAILY</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>GUARANTEED ROI</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>24/7 SUPPORT</p>
          <i class='bx bxs-info-circle'></i>
        </div>
      </div>

      <div class="third-section top">
        <p class="text-1">Diamond</p>
        <p class="text-2">30<span class="text-2-inner">%</span></p>
        <!--        <p class="text-2">4 Months</p>-->
        <p class="text-3">12 Months</p>

        <div class="btn" @click="onPostClick" >
          <p>Invest Now</p>
        </div>

        <div class="separate">
          <p>$100,000 MINIMUM DEPOSIT</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>ENHANCED SECURITY</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>1.6% DAILY</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>GUARANTEED ROI</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>24/7 SUPPORT</p>
          <i class='bx bxs-info-circle'></i>
        </div>
      </div>

      <div class="third-section top">
        <p class="text-1">Platinum</p>
        <p class="text-2">50<span class="text-2-inner">%</span></p>
        <!--        <p class="text-2">4 Months</p>-->
        <p class="text-3">24 Months</p>

        <div class="btn" @click="onPostClick" >
          <p>Invest Now</p>
        </div>

        <div class="separate">
          <p>$250,000 MINIMUM DEPOSIT</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>ENHANCED SECURITY</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>1.8% DAILY</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>GUARANTEED ROI</p>
          <i class='bx bxs-info-circle'></i>
        </div>

        <div class="separate">
          <p>24/7 SUPPORT</p>
          <i class='bx bxs-info-circle'></i>
        </div>
      </div>

    </div>

<!--    <div class="section-1">-->

<!--      <p class="header">General FAQs</p>-->

<!--      <section>-->
<!--        <details>-->
<!--          <summary>-->
<!--            WHAT IS EARLY WEALTH-->
<!--          </summary>-->
<!--          <p>-->
<!--            Early Wealth is an officially registered company and conducts-->
<!--            business under the laws of the UK Government. The company is involved-->
<!--            in Stocks, options CFD , Forex and crypto. Also trading other valuable-->
<!--            commodities/assets which we offer to our clients to earn from using a-->
<!--            single trading account.-->
<!--          </p>-->
<!--        </details>-->
<!--        <details>-->
<!--          <summary>-->
<!--            HOW SAFE ARE MY INVESTMENTS IN YOUR COMPANY-->
<!--          </summary>-->
<!--          <p>-->
<!--            It is absolutely safe because all our technical experts are extremely-->
<!--            experienced professionals; our Stocks, options CFD , Forex and crypto sourcing and-->
<!--            other investment strategies are proven to work.-->
<!--          </p>-->
<!--        </details>-->

<!--        <details>-->
<!--          <summary>-->
<!--            HOW CAN I MAKE SURE YOUR COMPANY IS A REAL AND NOT A FAKE ONE-->
<!--          </summary>-->
<!--          <p>-->
<!--            Early Wealth is officially incorporated with the UK-->
<!--            Companies Office. verify registration: (click here)-->
<!--&lt;!&ndash;            <a href="https://find-and-update.company-information.service.gov.uk/company/10477019">&ndash;&gt;-->
<!--&lt;!&ndash;              https://find-and-update.company-information.service.gov.uk/company/10477019&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--          </p>-->
<!--        </details>-->
<!--        <details>-->
<!--          <summary>-->
<!--            CAN I LOOSE MONEY IF I INVEST NOW?-->
<!--          </summary>-->
<!--          <p>-->
<!--            In accordance to investment laws of the FCA with over £3Billion-->
<!--            investment security, we never worry about those rainy days in global-->
<!--            trades and projects as we have our investors covered-->
<!--          </p>-->
<!--        </details>-->

<!--        <details>-->
<!--          <summary>-->
<!--            DO YOU PROVIDE PROOF OF YOUR TRADING  ACTIVITIES-->
<!--          </summary>-->
<!--          <p>-->
<!--            No, at the moment this is a closed information, but in the future we-->
<!--            plan to publish our trade reports regularly. We will announce further,-->
<!--            follow our news.-->
<!--          </p>-->
<!--        </details>-->


<!--      </section>-->

<!--    </div>-->

    <br/>
    <br/>


  </div>
</template>

<script>
export default {
  name: "HomeSection8",
  methods: {
    onPostClick() {
      this.$router.push("/register");
      window.scrollTo(0, 0);
    },
  },
}
</script>

<style scoped>

.alpha{
  /*padding-top: 5%;*/
  /*!*padding-bottom: 3%;*!*/
  /*!*background-image: url("@/assets/background.png");*!*/
  /*!*background-color: #000;*!*/
  /*height: 100%;*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*position: relative;*/
  /*padding-bottom: 5%;*/
  background: #f4f5f6;
  height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 5%;
  padding-top: 5%;
}
.section-header{
  font-size: 17px;
  color: #0f171c;
  text-align: center;
  font-weight: 300;
  margin-left: 23%;
  margin-right: 23%;
}
.section-header-1{
  font-size: 33px;
  color: #222222;
  font-weight: bold;
  text-align: center;
  padding-bottom: 1%;
  font-family: 'BR-Firma-Bold', sans-serif;
}
.section-alpha{
  display: flex;
  justify-content: space-evenly;
  margin-right: 3%;
  margin-left: 3%;
}

.section-alpha-2{
  display: flex;
  justify-content: space-evenly;
}

.section-alpha-1{
  display: block;
  text-align: center;
  margin-bottom: 2%;
}

.third-section{
  width: 300px;
  height: 480px;
  background-color: #ffffff;
  border: 1px solid #e4e8ee;
  /*box-shadow:  5px 5px 5px #d9d9d9,*/
  /*-5px -5px 5px #d9d9d9;*/
  border-radius: 12px;
  margin-bottom: 5%;
}

.separate{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 1%;
}

.separate i{
  padding-top: 15px;
  padding-right: 13px;
}

.separate p{
  width: 220px;
  padding-top: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #667085;
  padding-left: 13px;
}

.btn{
  margin-top: 5%;
  margin-bottom: 1%;
}

.btn p{
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 180px;
  height: 44px;
  background: #01A781FF;
  border: 1px solid #01A781FF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  text-align: center;
}



.top{
  margin-top: 1%;
}

.text-1{
  text-align: center;
  padding-top: 9%;
  padding-bottom: 3%;
  font-size: 19px;
  font-family: 'BR-Firma-Bold', sans-serif;
}

.text-2{
  text-align: center;
  padding-bottom: 4%;
  font-size: 27px;
  font-weight: bold;
}

.text-2-inner{
  font-size: 14px;
  font-weight: normal;
}

.text-3{
  text-align: center;
  font-size: 16px;
  width: 70%;
  display: block;
  margin-right: auto;
  margin-left: auto;
}


.bx{
  color: #01A781FF;
}

.section-1{
  padding-top: 4%;
  display: flex;
  justify-content: space-evenly;
  /*align-content: center;*/
  /*align-items: center;*/
}
.header{
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  font-size: 23px;
  line-height: 1.26;
  margin: 0;
  color: #0f171c;
  width: 25%;
  font-family: 'BR-Firma-Bold', sans-serif;
}
.lawrence{
  padding-left: 5%;
}
.lawrence li{
  padding-bottom: 2px;
}
section {
  width: 42%;
  /*margin: auto;*/
}

details[open] summary ~ * {
  animation: open 0.4s ease-in;
}

@keyframes open {
  0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  60% {
    transform: translateX(10px);
  }
  70% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(5px);
  }
  90% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
details summary::-webkit-details-marker {
  display: none;
}

details summary {
  width: 100%;
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 900;
  list-style: none;
  /*padding-top: 18px;*/
  /*padding-bottom: 10px;*/
  text-transform: uppercase;
  color: #222222;
}

details summary:after {
  content: "+";
  color: #0f171c;
  position: absolute;
  font-size: 1.7rem;
  line-height: 0;
  margin-top: 0.75rem;
  right: 0;
  font-weight: bold;
  transform-origin: center;
  transition: 200ms linear;
}
details[open] summary:after {
  transform: rotate(45deg);
  font-size: 2rem;
}
details summary {
  outline: 0;
}
details p {
  font-size: 16px;
  margin: 0 0 1rem;
  padding-top: 1rem;
  line-height: 1.5;
}
details {
  /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);*/
  position: relative;
  padding: 15px;
  margin-bottom: 2%;
  /*border-radius: 5px;*/
  border-bottom: solid 0.125rem #e7eaf0;
}
@media (max-width: 990px) {

}

@media (max-width: 700px) {
  .section-1{
    display: block;
    width: 100%;
  }
  .header{
    width: 100%;
    text-align: center;
  }
  .details summary {
    font-size: 20px;
  }
  details p {
    font-size: 16px;
    margin: 0 0 1rem;
    padding-top: 1rem;
  }
  summary{
    font-size: 20px;
  }
  section {
    width: 82%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .section-alpha{
    display: block;
    /*margin-left: 21%;*/
    margin-top: 3.5%;
  }
  .section-alpha-2{
    display: block;
    /*margin-left: 21%;*/
    margin-top: 3.5%;
  }
  .third-section{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .section-header-1{
    font-size: 27px;
  }

  .section-header{
    margin-left: 3%;
    margin-right: 3%;
  }
}

@media (max-width: 500px) {
}

</style>