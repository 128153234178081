var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alpha"},[_vm._m(0),_c('div',{staticClass:"section-alpha"},[_c('div',{staticClass:"third-section top"},[_c('p',{staticClass:"text-1"},[_vm._v("Silver")]),_vm._m(1),_c('p',{staticClass:"text-3"},[_vm._v("4 Months")]),_c('div',{staticClass:"btn",on:{"click":_vm.onPostClick}},[_c('p',[_vm._v("Invest Now")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"third-section top"},[_c('p',{staticClass:"text-1"},[_vm._v("Gold")]),_vm._m(7),_c('p',{staticClass:"text-3"},[_vm._v("8 Months")]),_c('div',{staticClass:"btn",on:{"click":_vm.onPostClick}},[_c('p',[_vm._v("Invest Now")])]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)]),_c('div',{staticClass:"third-section top"},[_c('p',{staticClass:"text-1"},[_vm._v("Diamond")]),_vm._m(13),_c('p',{staticClass:"text-3"},[_vm._v("12 Months")]),_c('div',{staticClass:"btn",on:{"click":_vm.onPostClick}},[_c('p',[_vm._v("Invest Now")])]),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18)]),_c('div',{staticClass:"third-section top"},[_c('p',{staticClass:"text-1"},[_vm._v("Platinum")]),_vm._m(19),_c('p',{staticClass:"text-3"},[_vm._v("24 Months")]),_c('div',{staticClass:"btn",on:{"click":_vm.onPostClick}},[_c('p',[_vm._v("Invest Now")])]),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24)])]),_c('br'),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-alpha-1"},[_c('p',{staticClass:"section-header-1"},[_vm._v("Choose an Investment Plan")]),_c('p',{staticClass:"section-header"},[_vm._v("You don't have an account yet? start trading with TopLeads Trade.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-2"},[_vm._v("10"),_c('span',{staticClass:"text-2-inner"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("$5,000 MINIMUM DEPOSIT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("ENHANCED SECURITY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("1.2% DAILY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("GUARANTEED ROI")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("24/7 SUPPORT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-2"},[_vm._v("20"),_c('span',{staticClass:"text-2-inner"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("$25,000 MINIMUM DEPOSIT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("ENHANCED SECURITY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("1.4% DAILY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("GUARANTEED ROI")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("24/7 SUPPORT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-2"},[_vm._v("30"),_c('span',{staticClass:"text-2-inner"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("$100,000 MINIMUM DEPOSIT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("ENHANCED SECURITY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("1.6% DAILY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("GUARANTEED ROI")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("24/7 SUPPORT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-2"},[_vm._v("50"),_c('span',{staticClass:"text-2-inner"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("$250,000 MINIMUM DEPOSIT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("ENHANCED SECURITY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("1.8% DAILY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("GUARANTEED ROI")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("24/7 SUPPORT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
}]

export { render, staticRenderFns }